.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.light {
  --background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  --logo: url('./1-dark-tight.svg');


}

.dark {
  --background: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  --logo: url('./3-light-tight.svg');
}
/*
body {
  --logo: url('./1-dark-tight.svg');
}
*/


.container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  align-content: flex-start;
  flex-direction: row;
} 


/*
.logo-dark {
  align-self: flex-start;
  content: url('./1-dark-tight.svg');
  width: 25%;
}

.logo-light {
  align-self: flex-start;
  content: url('./3-light-tight.svg');
  width: 25%;
}
*/
.loginlogo {
  align-items: center; 
  width: 100%; 
  content: var(--logo);
  margin-bottom: 15px;
}

.loginform-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo {
  align-self: flex-start; 
  width: 50%; 
  content: var(--logo); 
  align-content: center; 
}


.floppy {
  background-color: #ce7e00;
  fill: white;
  color: red;
  border-radius: 5px;
  margin: 2px;
  border-color: #000000;
  border-width: 2px;
}

.floppy:hover {
  background-color: #915900;
  fill: yellow;
  color: green;
}



input {

  /*
    border-left: 0.01rem solid #CDCDCD;
    border-top: 0.01rem solid #CDCDCD; */
    border-bottom: 0.05rem solid #ce7e00;
    border-right: 0.05rem solid #ce7e00;
    color: #000000;
    background-color: #bbbbbb;
    scrollbar-face-color: #ce7e00;

  } 

input.dark {

  /*
    border-left: 0.01rem solid #CDCDCD;
    border-top: 0.01rem solid #CDCDCD; */
    border-bottom: 0.05rem solid #ce7e00;
    border-right: 0.05rem solid #ce7e00;
    color: #ffffff;
    background-color: #000000;
    scrollbar-face-color: #ce7e00;

  } 

.button {
  color: greenyellow;
}




Dropdown {
  color: black;
  
  float: left;
}

#dropdown-basic {
  color: black;
  background-color: #ce7e00;
  border-color: #995e00;
}

html {
  background-color: var(--background);
}

div.modal-content {
  background-color: black;
  color: white;
}

div.modal-content .btn {
  background-color: "#ce7e00";
  color: "black";
  border-color: "#ce7e00";
}

.btn {
  background-color: red;
}
.btn-primary {
  background-color: yellowgreen;
}

.div #range {
  color: white;
}